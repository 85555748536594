import React, { useId } from 'react';
import CheckboxBase from '@mui/material/Checkbox';
import FormError from '../FormError/FormError';
import css from './checkbox.module.scss';
import clsx from 'clsx';

type Props = {
  label?: React.ReactNode;
  defaultChecked?: boolean;
  error?: string;
} & React.ComponentProps<typeof CheckboxBase>;

const Checkbox: React.FC<Props> = (props) => {
  const fallbackId = useId();

  const {
    id = `checkbox_${fallbackId}`,
    label,
    title,
    defaultChecked = false,
    checked,
    error,
    ...rest
  } = props;

  return (
    <div className={clsx(css.root, css['checkbox'])} title={title}>
      <CheckboxBase
        disableRipple
        id={id}
        {...rest}
        className={css.checkbox}
        checked={checked || defaultChecked}
      />
      {label && (
        <label htmlFor={id} className={css.label}>
          {label}
        </label>
      )}
      <FormError error={error} />
    </div>
  );
};

export default Checkbox;
