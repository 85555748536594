import React, { useEffect, useState } from 'react';
import { IconButton, Dialog, DialogContent, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useLocalStorageState from 'use-local-storage-state';
import { ModalClose } from '../../../components';
import TransferList from '../../../components/v2/TransferList/TransferList';

type Props = {
  keyPrefix?: string;
  attributes: {
    [key: string]: string;
  };
  enabledAttributes: string[];
  onChange(attributes: string[]): void;
  reportId?: string;
  renderButton?(props: { onClick(): void }): React.ReactNode;
};

const defaultRenderButton: Props['renderButton'] = (props) => (
  <IconButton {...props} size="large">
    <MoreVertIcon />
  </IconButton>
);

const AttributesManager: React.FC<Props> = ({
  keyPrefix = '',
  attributes: defaultAttributes,
  enabledAttributes,
  onChange,
  reportId = '',
  renderButton = defaultRenderButton,
}) => {
  const [active, setActive] = useState(false);
  const [availableAttributes, setAvailableAttributes] = useState<string[]>(
    Object.keys(defaultAttributes),
  );
  const [defaultEnabled, setDefaultEnabled] = useState(
    Array.from(enabledAttributes || []),
  );
  const [attributes, setAttributes] = useLocalStorageState<string[]>(
    `${keyPrefix}_report_custom_attributes_${reportId}`,
    {
      defaultValue:
        defaultEnabled.length > 0 ? defaultEnabled : availableAttributes,
    },
  );

  const mapTransferItem = (item: string) => ({
    id: item,
    label: defaultAttributes[item],
  });

  useEffect(() => {
    const nextAttributes = attributes.filter((i) =>
      availableAttributes.includes(i),
    );
    onChange(nextAttributes);
  }, [availableAttributes, attributes, onChange]);

  const selectedAttributes = attributes.filter((i) =>
    availableAttributes.includes(i),
  );

  return (
    <Box sx={{ textAlign: 'right' }}>
      {renderButton({ onClick: () => setActive(true) })}
      {active && (
        <Dialog open maxWidth="md" fullWidth onClose={() => setActive(false)}>
          <ModalClose onClose={() => setActive(false)} />
          <DialogContent sx={{ pt: 8, pb: 2 }}>
            {/*<TransferList*/}
            {/*  available={availableAttributes.map(mapTransferItem)}*/}
            {/*  selected={attributes.map(mapTransferItem)}*/}
            {/*  onSubmit={(items) => {*/}
            {/*    setAttributes(items.map((item) => item.id));*/}
            {/*    setActive(false);*/}
            {/*  }}*/}
            {/*/>*/}
            <TransferList
              available={availableAttributes.map(mapTransferItem)}
              selected={selectedAttributes.map(mapTransferItem)}
              onSubmit={(items) => {
                setAttributes(items.map((item) => item.id));
                setActive(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default AttributesManager;
