import React, { PropsWithChildren, useEffect, useState, useMemo } from 'react';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes, RouteConfig } from 'utils/render-routes';
import ContextMenuProvider from './components/v2/ContextMenu/ContextMenuProvider';
import { applyFilters, bootstrap, Filters } from './core';
import TwoFactorProvider from './modules/auth/providers/TwoFactorProvider';
import UiVersionProvider from './modules/ui/UserInterfaceVersionProvider';
import { configureStore, RootState } from './store';
import http, { injectStore } from './utils/http';
import {
  ScrollReset,
  LocaleUpdater,
  GoogleAnalytics,
  FixedLoader,
  PwaDynamicManifest,
  Compose,
} from 'components';
import { generateRoutes } from './routes';
import { fetchUser } from './modules/auth/actions';
import { useAppSettings } from './modules/ui/hooks';
import configureTheme from './theme';
import FilePreviewProvider from './modules/files/providers/FilePreviewProvider';
import { DrawerContextProvider } from './views/MyDisk/DrawerContext';
import SnackBarsProviderLegacy from './components/Snackbar/SnackBarsProvider';
import SnackbarsProvider from './components/v2/Snackbar/SnackbarsProvider';
import DirectoryPickerProvider from './components/DirectoryPicker/DirectoryPickerProvider';
import SwitchedUserNotification from './modules/auth/components/SwitchedUserNotification';
import { ModalProvider as ModalProviderLegacy } from './components/Modal/ModalProvider';
import ModalProvider from './components/v2/Modal/ModalProvider';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import GlobalDynamicStyles from './components/Page/GlobalDynamicStyles';
import QueryClientProvider from './utils/QueryClientProvider';

export const store = configureStore();
injectStore(store);

interface RouterProps extends PropsWithChildren {
  routes: RouteConfig[];
}

const AppRouter: React.FC<RouterProps> = ({ routes }) => {
  const dispatch = useDispatch();
  const { accessToken, isUserLoading } = useSelector(
    (state: RootState) => ({
      accessToken: state.auth.accessToken,
      isUserLoading: state.auth.isUserLoading,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchUser.trigger());
    }
    // eslint-disable-next-line
  }, []);

  if (isUserLoading) {
    return <FixedLoader />;
  }

  return renderRoutes(routes);
};

const fetchConfig = async () => {
  return http.get('/system/config');
};

const AppRouterWrapper = ({ routes, providers }: any) => (
  <Compose
    components={[
      UiVersionProvider,
      QueryClientProvider,
      FilePreviewProvider,
      DrawerContextProvider,
      DirectoryPickerProvider,
      SnackBarsProviderLegacy,
      SnackbarsProvider,
      ModalProviderLegacy,
      ModalProvider,
      TwoFactorProvider,
      ContextMenuProvider,
    ]}
  >
    <BrowserRouter>
      {providers.map((Component: any, index: number) => (
        <Component key={index} />
      ))}
      <ScrollReset />
      <LocaleUpdater />
      <GoogleAnalytics />
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AppRouter routes={routes} />
      </QueryParamProvider>
      <SwitchedUserNotification />
    </BrowserRouter>
  </Compose>
);

const App: React.FC = () => {
  const [ready, setReady] = useState(false);
  const [routes, setRoutes] = useState<RouteConfig[]>([]);
  const { settings, setSettings } = useAppSettings();
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const appSettings = useSelector((state: RootState) => state.ui.app);
  const theme = useMemo(() => configureTheme(settings), [ready, settings]);

  useEffect(() => {
    if (document && appSettings.webFavicon) {
      const icon = document.getElementById('app-favicon');
      if (icon) {
        icon.setAttribute('href', appSettings.webFavicon);
      }
    }
  }, [appSettings.webFavicon]);

  useEffect(() => {
    fetchConfig().then(async (config) => {
      // Bootstrap Application, give opportunity for plugins to install
      console.log('BEFORE BOOTSTRAP');
      await bootstrap({ store });
      console.log('AFTER BOOTSTRAP');
      const extraRoutes = applyFilters(Filters.app.extraRoutes, []);
      const extraPublicRoutes = applyFilters(Filters.app.extraPublicRoutes, []);
      const extraRootRoutes = applyFilters(Filters.app.extraRootRoutes, []);
      const routes = generateRoutes(
        config,
        extraRoutes,
        extraPublicRoutes,
        extraRootRoutes,
      );
      setSettings(config);
      setRoutes(routes);
    });

    // eslint-disable-next-line
  }, [accessToken]);

  const providers: React.ComponentType[] = applyFilters(
    Filters.app.providers,
    [],
  );

  if (0 === routes.length) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PwaDynamicManifest config={settings} />
          <GlobalDynamicStyles config={settings} />
          <AppRouterWrapper routes={routes} providers={providers} />
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export const ReduxProvider = ({ children }: React.PropsWithChildren) => (
  <Provider store={store}>{children}</Provider>
);

const AppWithProviders = () => (
  <ReduxProvider>
    <App />
  </ReduxProvider>
);

export default class AppWithErrorHandler extends React.Component {
  componentDidCatch(error: Error) {
    switch (error.name) {
      case 'ChunkLoadError':
        if (window) {
          // when chunk cannot be found (usually after deployment new version)
          // then refresh the page.
          window.location.reload();
        }
        break;
      default:
        throw error;
    }
  }

  render() {
    return <AppWithProviders />;
  }
}
