import React, { useState } from 'react';
import css from './cover.module.scss';
import { useDropzone } from 'react-dropzone';
import { getFilesFromEvent } from '../../FilesDropzone/FilesDropzone';
import { useUserIsAdmin } from '../../../modules/auth/hooks';
import DropOverlay from '../DropOverlay/DropOverlay';
import Icon from '../Icon/Icon';
import Loader from '../Loader';
import Button from '../Button/Button';
import { useTranslation } from 'utils/translation';
import Typography from '../Typography/Typography';

type Props = {
  url?: string;
  onUpload?(file: File): Promise<any>;
  onDestroy?(): void;
};

export const Cover: React.FC<Props> = ({ url, onUpload, onDestroy }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [isBusy, setBusy] = useState(false);
  const fallback = '/images/placeholder.png';
  const canUpload = useUserIsAdmin();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file && onUpload) {
        try {
          setBusy(true);
          await onUpload(file);
        } finally {
          setBusy(false);
        }
      }
    },
    disabled: !canUpload,
    // @ts-ignore
    getFilesFromEvent,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const rootProps = getRootProps();
  delete rootProps.onClick;
  delete rootProps.onBlur;
  delete rootProps.onFocus;

  return (
    <div className={css.root} {...rootProps}>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <img
            className={css.image}
            src={error ? fallback : url || fallback}
            onError={() => setError(true)}
            alt=""
            loading="lazy"
          />
          {!url && canUpload && (
            <div className={css.uploadInfo}>
              <Typography component="span" variant="label3">
                {t('drag_image_here')}
              </Typography>
            </div>
          )}
        </>
      )}
      <input id="cover-upload-input-via-drop" {...getInputProps()} />
      {isDragActive && (
        <DropOverlay icon={(p) => <Icon {...p} name="fa-regular fa-image" />}>
          {t('assets.upload_new_cover_image')}
        </DropOverlay>
      )}
      {!isBusy && onDestroy && (
        <div className={css.overlay}>
          <Button
            color="error"
            size="small"
            onClick={onDestroy}
            startIcon={<Icon name="fa-regular fa-trash" />}
          >
            {t('Remove')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Cover;
