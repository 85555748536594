import React, { useImperativeHandle, useMemo } from 'react';
import { FormProps } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonGroup } from '../Button/Button';
import { useTranslation } from 'utils/translation';
import { useShallowEqualEffect } from '../../../utils/use-shallow-effect';
import Field from './Field';
import Fields from './Fields';

function Form<Values extends object>(
  props: React.PropsWithChildren<FormProps<Values>>,
) {
  const {
    defaultValues,
    errors: defaultErrors = {},
    onBeforeSubmit,
    onSubmit = () => Promise.resolve(),
    onCancel,
    onChange,
    fields = [],
    slots: { save, cancel } = {},
    children,
    renderButtons = true,
    buttonAlign = 'right',
    extraActions = null,
    formRef,
  } = props;

  const { t } = useTranslation();
  const methods = useForm<Values>({
    defaultValues,
  });

  useImperativeHandle(formRef, () => methods);

  const { handleSubmit, formState } = methods;

  const contextValue = useMemo(() => {
    const errors = {
      ...defaultErrors,
      ...formState.errors,
    };

    return {
      ...methods,
      formState: {
        ...formState,
        errors,
      },
    };
  }, [formState, methods, defaultErrors]);

  const values = contextValue.watch();

  useShallowEqualEffect(() => {
    onChange?.(values);
  }, [onChange, values]);

  return (
    <FormProvider {...contextValue}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields?.length > 0 && <Fields fields={fields} />}
        {children}
        {renderButtons && (
          <ButtonGroup align={buttonAlign}>
            <Button
              type="submit"
              onClick={onBeforeSubmit}
              isBusy={methods.formState.isSubmitting}
            >
              {save || t('form.submit')}
            </Button>
            {onCancel && (
              <Button onClick={onCancel} variant="text">
                {cancel || t('form.cancel')}
              </Button>
            )}
            {extraActions}
          </ButtonGroup>
        )}
      </form>
    </FormProvider>
  );
}

export default Object.assign(Form, {
  Fields,
  Field,
});
