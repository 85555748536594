import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '../../Checkbox/Checkbox';
import FormControl from '../../FormControl/FormControl';
import { FieldArgs, CheckboxField } from '../types';
import { useFormError } from '../utils';

export type CheckboxAdapterProps = FieldArgs<CheckboxField>;

const CheckboxAdapter = (props: CheckboxAdapterProps) => {
  const { label, name, rules, defaultChecked, ...rest } = props;
  const { control, formState } = useFormContext();
  const error = useFormError(get(formState.errors, name));

  return (
    <FormControl error={!!error}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, disabled, value } }) => {
          return (
            <Checkbox
              {...rest}
              checked={value}
              name={name}
              disabled={disabled}
              value={!!value}
              onChange={onChange}
              error={error}
              label={label}
            />
          );
        }}
      />
    </FormControl>
  );
};

export default CheckboxAdapter;
