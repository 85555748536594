import React, { useRef } from 'react';
import { MultipleContainers } from './MultipleContainers';
import { Button, ButtonGroup } from '../Button/Button';
import Spacer from '../Spacer/Spacer';

interface Item {
  id: string;
  label: string;
}

interface TransferListProps {
  available: Item[];
  selected: Item[];
  onSubmit?(selected: Item[]): void;
}

const TransferList: React.FC<TransferListProps> = ({
  available,
  selected,
  onSubmit,
}) => {
  const selectedIds = selected.map((i) => i.id);
  const availableItems = available.filter((i) => !selectedIds.includes(i.id));
  const itemsRef = useRef({
    selected: [] as any,
  });

  return (
    <div>
      <MultipleContainers
        items={{
          available: availableItems,
          selected,
        }}
        onChange={(items) => (itemsRef.current.selected = items.selected)}
      />
      <Spacer size={2} />
      <ButtonGroup align="right">
        <Button onClick={() => onSubmit?.(itemsRef.current.selected)}>
          Save
        </Button>
      </ButtonGroup>
    </div>
  );
};
export default TransferList;
